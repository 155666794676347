import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import { Typography, Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

const useStylesAddIcon = makeStyles((theme) => ({
  root: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // fontSize: "30px !important",
    // display: "inline-block",
    // padding: "0 15px",
    // background:"trasparent",
    // transform:"rotate(45deg)"
  },
  close: {
    width: 50,
    height: 50,
    opacity: 1,
    transition: "all 0.3s ease 0s",
    "&:hover": {
      height: 40,
      width: 40,
    },
    // -moz-transition: all 0.3s ease 0s;
    // -o-transition: all 0.3s ease 0s;
    // -ms-transition: all 0.3s ease 0s;
    // -webkit-transition: all 0.3s ease 0s;
    // transition: "all 0.3s ease 0s"
  },
}));
const AddIcon = () => {
  const classes = useStylesAddIcon();
  return (
    <div className={classes.root}>
      <img
        alt={"close icon"}
        src={require("../../../assets/menu/close-icon.svg")}
        className={classes.close}
      />
    </div>
  );
};

const styleDialog = (theme) => ({
  root: {
    //  "& .MuiDialog-root":{
    zIndex: "1400 !important",
    position: "fixed !important",
    //  },
    "& .MuiDialog-paper": {
      background:
        "transparent linear-gradient(90deg, #090C1F 0%, #202747 50%, #0F132B 100%) 0% 0% no-repeat padding-box",
      [theme.breakpoints.down(600)]: {
        // height: "80vh",
        margin: "0",
      },
      // [theme.breakpoints.down(960)]: {
      //   margin: 0,
      // },
    },
    "& .MuiDialog-paperFullWidth": {
      [theme.breakpoints.down(600)]: {
        width: "calc(100% - 20px)",
      },
    },
    "& .MuiDialogTitle-root": {
      padding: 0,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.3) !important",
    },
    "& .MuiDialogContent-root": {
      padding: "0px 0px 30px",
      [theme.breakpoints.down(600)]: {
        padding: "0px 0px 30px",
      },
    },
  },
});
const CustomDialog = withStyles(styleDialog)(Dialog);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Books = ({
  className,
  titulo,
  link,
  buttonlink = null,
  buttontext = null,
}) => {
  const [open, setOpen] = useState(false);
  const useStylesPopup = makeStyles((theme) => ({
    buttonClose: {
      position: "absolute",
      top: 47,
      right: 0,
      height: 50,
      width: 50,
      minWidth: 50,
      padding: 0,
      fontSize: 14,
      fontFamily: "Roboto-Light",
      textTransform: "none",
      color: "#ffffff !important",
      background: "#000000d1",
      borderRadius: 0,
      textAlign: "initial",
      zIndex: 9999,
      "&:hover": {
        color: "#ffffff !important",
        background: "#000000d1",
      },
    },
    root: {
      "& >div": {
        display: "flex",
      },
    },
    iframe: {
      height: "800px !important",
      width: "100%",
    },
    paperQR: {
      position: "relative",
      background: "transparent",
    },
    //   containerDescripcion: {
    //     padding: "0 15px",
    //     textAlign: "center",
    //     [theme.breakpoints.up("sm")]: {
    //       padding: "0 30px",
    //       display: "flex",
    //     },
    //   },

    descripcion: {
      marginTop: 15,
      fontSize: 14,
      color: "#ffffff",
      fontFamily: "Minion-Italic",
      letterSpacing: "0.42px",
      lineHeight: "28px",
      textAlign: "center",

      [theme.breakpoints.up("sm")]: {
        letterSpacing: "0.54px",
        lineHeight: "32px",
        fontSize: 18,
        // paddingRight: 10,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: 25,
        maxWidth: 600,
        margin: "auto",
      },
    },
    vimeo: {
      width: "100%",
      margin: "auto",
      maxWidth: "100%",
      height: "210px !important",
      minHeight: "210px",
      [theme.breakpoints.up(400)]: {
        height: "240px !important",
        minHeight: "240px",
      },
      [theme.breakpoints.up("sm")]: {
        // width: 500,
        height: "280px !important",
      },
      [theme.breakpoints.up("md")]: {
        // width: 500,
        height: "340px !important",
      },
      [theme.breakpoints.up("lg")]: {
        height: "340px !important",
      },
      [theme.breakpoints.up("xl")]: {
        height: "725px !important",
      },
    },
    containerPortada: {
      backgroundColor: "black",
      overflow: "hidden",
      position: "relative",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      minHeight: 210,
      [theme.breakpoints.up("sm")]: {
        width: 500,
        height: 280,
      },
      [theme.breakpoints.up("md")]: {
        width: 500,
        height: 280,
      },
      [theme.breakpoints.up("lg")]: {
        width: 685,
        height: 385,
      },
    },
    buttonClose2: {
      position: "absolute",
      top: 0,
      right: 0,
      height: 50,
      width: 50,
      minWidth: 50,
      padding: 0,
      fontSize: 14,
      fontFamily: "Roboto-Light",
      textTransform: "none",
      color: "#ffffff !important",
      background: "#000000d1",
      borderRadius: 0,
      textAlign: "initial",
      zIndex: 9999,
      "&:hover": {
        color: "#ffffff !important",
        background: "#000000d1",
      },
      // [theme.breakpoints.up("sm")]: {
      //   position: "relative",
      // },
      [theme.breakpoints.up("sm")]: {
        position: "absolute",

        top: "auto",
        left: "auto",
        bottom: -30,
      },
    },
    rootClose: {
      width: 50,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    close: {
      width: 50,
      height: 50,
      opacity: 1,
      transition: "all 0.3s ease 0s",
      "&:hover": {
        height: 40,
        width: 40,
      },
    },
    logo: {
      height: 100,
      width: 100,
      margin: "auto",
      marginBottom: 42,
      marginTop: 30,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      background: `url(${require("../../../assets/logo_sena_color.png")})`,
      [theme.breakpoints.up("md")]: {
        height: 150,
        width: 150,
      },
    },
    buttonlink: {
      position: "absolute",
      bottom: 8,
      right: 8,
      height: 36,
      width: "max-content",
      padding: "0 30px",
      fontSize: 14,
      fontFamily: "Roboto-Light",
      textTransform: "none",
      color: "#a37644 !important",
      border: "1px solid #a37644",
      background: "#fff !important",
      borderRadius: 0,
      textAlign: "initial",
      zIndex: 99999,
    },
  }));
  const classes = useStylesPopup();

  const handleClose = () => {
    //   setPopup(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Typography className={className} onClick={() => setOpen(true)}>
        {titulo.map((ti, index) => (
          <React.Fragment key={index}>
            {ti}
            <br />
          </React.Fragment>
        ))}
      </Typography>
      <CustomDialog
        open={open}
        onClose={handleClose}
        fullScreen
        className={classes.root}
        TransitionComponent={Transition}
      >
        <Button className={classes.buttonClose} onClick={handleClose}>
          <AddIcon className={classes.iconClose} />
        </Button>
        <iframe
          style={{ position: "relative", top: 0, zIndex: 1 }}
          id="inlineFrameExample"
          title="Downloads"
          referrerPolicy="no-referrer"
          src={link}
        ></iframe>

        {buttonlink && buttontext && (
          <a
            href={buttonlink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ position: "relative", zIndex: 2 }}
          >
            <Button className={classes.buttonlink}>{buttontext}</Button>
          </a>
        )}
      </CustomDialog>
    </React.Fragment>
  );
};

export default Books;
